<template>
	<div class="app-container">
		<el-header class="titles">赛制管理</el-header>
		<el-card id="parent" class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>条件查询</span>
				<el-button id="child" @click="searchTable()" type="primary" size="small" icon="el-icon-search">搜索</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="阶段名称：">
						<el-input v-model="searchForm.name" placeholder="请输入阶段名称"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-button type="danger" size="medium" icon="el-icon-delete" @click="allDle()">批量删除</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button slot="reference" type="primary" size="medium" icon="el-icon-s-grid" circle></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="getList(1)"></el-button>
		</el-row>
		<el-card>
			<el-table v-loading="listLoading" ref="multipleTable" :data="tableData" :stripe="true" border style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column fixed type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" :sortable="item.sortable" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="defaultQuestionNum" label="默认题目数量" min-width="110"></el-table-column>
				<el-table-column prop="defaultScore" label="默认分值" min-width="110"></el-table-column>
				<el-table-column prop="defaultSort" label="默认考试顺序" min-width="110"></el-table-column> -->
				<el-table-column prop="createdTxStamp" label="更新日期" min-width="120"></el-table-column>
				<el-table-column fixed="right" label="操作" min-width="180">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteTable(scope.$index, scope.row)">删除</el-button>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="40%">
		  <el-form :model="form" :rules="rules" ref="ruleForm">
		    <el-form-item label="阶段名称：" prop="name" :label-width="formLabelWidth">
				<el-input v-model="form.name" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item label="编码：" prop="code" :label-width="formLabelWidth">
		      <el-input v-model="form.code" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item label="序号：" prop="sort" :label-width="formLabelWidth">
		      <el-input v-model="form.sort" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.sort = $event.target.value"></el-input>
		    </el-form-item>
			<el-form-item label="URL：" prop="url" :label-width="formLabelWidth">
			  <el-input v-model="form.url" autocomplete="off"></el-input>
			</el-form-item>
		    <el-form-item label="晋级人数：" prop="num" :label-width="formLabelWidth">
		      <el-input v-model="form.num" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.num = $event.target.value"></el-input>
		    </el-form-item>
			<el-form-item label="容纳人数：" prop="maxNum" :label-width="formLabelWidth">
			  <el-input v-model="form.maxNum" autocomplete="off" oninput="value=value.replace(/[^\d]/g,'')" @blur="form.maxNum = $event.target.value"></el-input>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogTitle: '修改数据',
				page: 1,
				searchForm: {
					name: ''
				},//条件查询form
				tableData: [],//表格
				multipleSelection: [],//表格选中数组
				total: 0,//分页总条数
				dialogFormVisible: false,//修改、新增弹窗
				formLabelWidth: '120px',//修改、新增弹窗-label宽度
				form: {
					name: '',
					code: '',
					sort: '',
					url: '',
					num: ''
				},//修改、新增弹窗-表单内容
				rules: {
					name: [{ required: true, message: '请输入赛制类型', trigger: 'blur' }],
					code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
					sort: [{ required: true, message: '请输入序号', trigger: 'blur' }],
					url: [{ required: true, message: '请输入url', trigger: 'blur' }],
					num: [{ required: true, message: '请输入晋级人数', trigger: 'blur' }],
					maxNum: [{ required: true, message: '请输入容纳人数', trigger: 'blur' }]
				},
				visible: false,
				checkArr: [//{label: "id", val: "id", dis: true},
				{label: "编码", val: "code", dis: true},
				{label: "阶段名称", val: "name", dis: true},
				{label: "排序", val: "sort", dis: true, sortable: true},
				{label: "晋级人数", val: "num", dis: true},
				{label: "容纳人数", val: "maxNum", dis: true},
				{label: "是否启用0否1是", val: "status", dis: true}],
				checkList: ["code", "name", "sort", "num", "maxNum", "status"],	
				// checkList: [{label: "类型1", val: "name"}, {label: "默认题目数量", val: "defaultQuestionNum"}, {label: "默认分值", val: "defaultScore"}, {label: "默认考试顺序", val: "defaultSort"}]
			}
		},
		components: {
			
		},
		created(){
			this.getList(1);
		},
		methods:{
			getList(page, val) {
				this.listLoading = true;
				val = val ? val : "";
				let data = {
					entity: 'FaExamGameStage',
					page: page,
					pageSize: 10,
					filter: '[{"col":"name","type":"like","val":"' + val + '"}]',
					// sidx: 'defaultSort',
					// sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					console.log(res)
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
				console.log(this.multipleSelection)
			},
			//批量删除
			allDle() {
				if(this.multipleSelection.length > 0) {
					let comments = this.multipleSelection
					let num = ""
					for (let i = 0;i<comments.length;i++){
						num = num + comments[i].id + ","
					}
					num = num.substr(0, num.length - 1)
					console.log(num)
					let data = {
						entity: 'FaExamGameStage',
						ids: num,
					}
					this.$confirm('是否删除', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$comjs.ajax.postAjax('/jqGrid/batchDelete', data, this, res => {
							this.$message({
								message: '删除成功！',
								type: 'success',
								duration: 1000
							});
						});
						for (let j = 0;j<comments.length;j++){
							this.tableData.splice(comments[j],1)
						}
					})
				}
				else {
					this.$message({
						message: '请选择要删除的内容！',
						type: 'error',
						duration: 1000
					});
				}
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList(val);
			},
			// 修改
			editTable(index, row) {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.dialogFormVisible = true;
				this.dialogTitle = "修改数据";
				let data = {
					entity: 'FaExamGameStage',
					id: row.id,
				}
				this.$comjs.ajax.getAjax('/jqGrid/commonFindDetailById', data, this, res => {
					console.log(res)
					this.form = {
						id: row.id,
						name: res.data.name,
						code: res.data.code,
						sort: res.data.sort,
						url: res.data.url,
						num: res.data.num,
						maxNum: res.data.maxNum
					}
				})
			},
			// 删除
			deleteTable(index, row) {
				let data = {
					entity: 'FaExamGameStage',
					id: row.id,
				}
				this.$comjs.ajax.postAjax('/jqGrid/commonDeleteById', data, this, res => {
					console.log(res)
					console.log(row.id)
					this.tableData.splice(index, 1);
				});
			},
			// 搜索
			searchTable() {
				let val = this.searchForm.name;
				this.page = 1;
				this.getList(this.page, val)
			},
			// 新增
			append() {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.dialogFormVisible = true;
				this.dialogTitle = "新增数据";
				this.form = {
					id: "",
					name: "",
					num: "",
					maxNum: "",
					status: "",
					url: "",
					sort: ""
				}
			},
			// 修改/新增-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							id: this.form.id ? this.form.id : "",
							name: this.form.name,
							code: this.form.code,
							sort: this.form.sort,
							url: this.form.url,
							num: this.form.num,
							maxNum: this.form.maxNum
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postAjax('/fa/exam/game/editGameStage', data, this, res => {
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '保存成功！',
									type: 'success',
									duration: 1000
								});
								this.getList(this.page, this.searchForm.name);
							});
						}).catch(() => {
							
						});
					}
				});
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	#parent {
		position: relative;
	}
	#child {
		position: absolute;
		height: 36px;
		width: 80px;
		margin-top:-18px;
		top: 50%;
		left: 88%;
	}
</style>


